import * as React from "react"

import Seo from "../components/seo"

import { Link } from "gatsby"


const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>There doesn't appear to be anything here. Sorry! </p><p>You should be able to find what you were looking from the <Link to="/">homepage.</Link></p>
  </>
)

export default NotFoundPage
